import {Component, OnInit} from '@angular/core';
import {BackOfficeService} from '../../services/back-office.service';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-customer-search-modal',
  templateUrl: './customer-search-modal.page.html',
  styleUrls: ['./customer-search-modal.page.scss'],
})
export class CustomerSearchModalPage implements OnInit {

  searchTerm: '';
  customerList: any = [];

  constructor(
    private backOffice: BackOfficeService,
    public modalController: ModalController
  ) {
  }

  ngOnInit() {
  }


  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  selectCustomer(theCustomer: any) {
    this.modalController.dismiss({
      dismissed: false,
      customer: theCustomer
    });
  }


  searchCustomer() {
    const request = this.backOffice.searchCustomer({query: this.searchTerm});
    request.subscribe(
      (success: any) => {
        // console.log(success);
        // this.backOffice.showAlert('Success', 'Spend authorization generated successfully.');
        this.customerList = success;
      },
      (failure: any) => {
        this.backOffice.showAlert('Error', failure.error.message);
      }
    );
  }

}
