import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {


  getTransactionColor(transaction: any) {
    if (transaction.amount < 0) {
      return '#FF7E87';
    }
    return '#00D793';
  }


  getTransactionImage(transaction: any) {
    if (transaction.amount < 0) {
      return '/assets/images/withdraw.png';
    }
    return '/assets/images/deposit.png';
  }
}
